
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import {
  connectivityDetector,
  AnalyzerData
} from '@/helpers/connectivity-helper'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

const ConnectivityStore = namespace('connectivity')

/**
 * Debug-Ansicht für die Verbindungserkennung.
 */
@Component({ components: { BaseFrame } })
export default class DebugConnectivity extends Vue {
  /**
   * Verbindungsstatus (online) aus dem Store.
   */
  @ConnectivityStore.Getter('online')
  public online!: boolean

  /**
   * Verbindungsstatus (offline) aus dem Store.
   */
  @ConnectivityStore.Getter('offline')
  public offline!: boolean

  /**
   * Analyse-Daten.
   */
  public analyzerData: AnalyzerData | null = null

  /**
   * ID der registrierten Analyzer-Funktion.
   */
  private _analyzerID?: number

  /**
   * Registriert den Analyzer, der die Daten abruft, die in der Debug-Ansicht
   * aufbereitet angezeigt werden.
   */
  public created(): void {
    this._analyzerID = connectivityDetector.registerAnalyzer(
      (debug: AnalyzerData): void => {
        this.analyzerData = debug
      }
    )
  }

  /**
   * Entfernt die Analyzer-Registrierung, bevor die Komponente zerstört wird.
   */
  public beforeDestroy(): void {
    if (this._analyzerID) {
      connectivityDetector.unregisterAnalyzer(this._analyzerID)
    }
  }

  /**
   * Liest die Keys des [[AnalyzerData.configuration]]-Objekts aus.
   *
   * @returns Array mit Keys des Objekts.
   */
  public get configurationKeys(): string[] {
    if (!this.analyzerData) {
      return []
    }

    return Object.keys(this.analyzerData.configuration)
  }
}
