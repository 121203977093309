
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Basis-Grundrahmen für die Views
 */
@Component({})
export default class BaseFrame extends Vue {
  /**
   * Style Klassen für das v-sheet
   */
  @Prop({ type: String, required: false })
  public classes?: string

  /**
   * Gibt an ob der Border-Radius entfernt werden soll
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isTile?: boolean

  /**
   * Fügt einen großen Border-Radius oben links und unten rechts hinzu
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isShaped?: boolean

  /**
   * Bestimmt den Border-Radius
   */
  @Prop({ type: Boolean || String, required: false, default: undefined })
  public isRounded?: boolean | string

  /**
   * Entfernt den `box-shadow` und fügt einen dünnen Rand hinzu
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isOutlined?: boolean

  /**
   * Bestimmt die minimale Breite des v-sheets
   */
  @Prop({ type: Number || String, required: false, default: undefined })
  public minimalWidth?: number | string

  /**
   * Bestimmt die maximale Breite des v-sheets
   */
  @Prop({ type: Number || String, required: false, default: undefined })
  public maximalWidth?: number | string

  /**
   * Bestimmt die Breite des v-sheets
   */
  @Prop({ type: Number || String, required: false, default: undefined })
  public generalWidth?: number | string

  /**
   * Bestimmt die minimale Höhe des v-sheets
   */
  @Prop({ type: Number || String, required: false, default: undefined })
  public minimalHeight?: number | string

  /**
   * Bestimmt die maximale Höhe des v-sheets
   */
  @Prop({ type: Number || String, required: false, default: undefined })
  public maximalHeight?: number | string

  /**
   * Bestimmt die Höhe des v-sheets
   */
  @Prop({ type: Number || String, required: false, default: undefined })
  public generalHeight?: number | string

  /**
   * Bestimmt die Intensität des `box-shadow`
   */
  @Prop({ type: Number || String, required: false, default: undefined })
  public boxShadow?: number | string

  /**
   * Aktiviert das Light-Theme
   */
  @Prop({ type: Boolean, required: false, default: false })
  public lightTheme?: boolean

  /**
   * Aktiviert das Dark-Theme
   */
  @Prop({ type: Boolean, required: false, default: false })
  public darkTheme?: boolean

  /**
   * Hintergrundfarbe des v-sheets
   */
  @Prop({ type: String, required: false, default: undefined })
  public backgroundColor?: string
}
